<template>
  <a-layout-content class="submain-container" :style="{ height: h }">
    <router-view />
  </a-layout-content>
</template>

<script>
export default {
  data() {
    return {
      collapsed: false,
      h: 0
    };
  },

  mounted() {
    window.addEventListener('resize', () => {
      this.mainHeight();
    });

    this.mainHeight();
  },

  methods: {
    onMenuChange(value) {
      this.collapsed = value;
    },

    mainHeight() {
      const h = document.documentElement.clientHeight;
      this.h = h - 64  - 24 * 4 + 'px';
    }
  }
};
</script>

<style>
.submain-container {
  /* padding: 24px;
  margin: 24px 16px; */
  background-color: #ffffff;
  overflow-y: auto;
}
</style>
