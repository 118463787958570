<template>
  <a-layout-header class="top">
    <span></span>
<!--    <a-icon class="trigger" :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click="leftMenuChange" />-->
    <div class="user-info">
      <a-dropdown>
        <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
          <span>{{ userInfo.roleName }}：{{ userInfo.name }} </span>
          <a-icon type="down" />
        </a>
        <a-menu slot="overlay" @click="onMenuItemTap">
          <a-menu-item key="out-login">
            <span>退出登录</span>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
  </a-layout-header>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: {
    collapsed: Boolean
  },

  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo
    })
  },

  methods: {
    leftMenuChange() {
      this.$emit('change', !this.collapsed);
    },

    onMenuItemTap({ key: type }) {
      if (type === 'update-password') {
        this.$router.push('/update-password');
      } else if (type === 'out-login') {
        this.$store.dispatch('FedLogOut').then(() => this.$router.push('/login'));
      }
    }
  }
};
</script>

<style lang="less" scoped>
.top {
  display: flex;
  justify-content: space-between;
  padding: 0;
  background-color: #ffffff;
  .user-info {
    margin-right: 20px;
  }
}
</style>
