export default [
  {
    path: '/login',
    name: '登录',
    component: () => import(/* webpackChunkName: "pages" */ '@/pages/login'),
    meta: {
      isAuth: false
    }
  },
  {
    path: '*',
    redirect: '/store'
  }
];
