import router from './router';
import store from './store';
import { getToken } from '@/utils/auth';

router.beforeEach((to, from, next) => {
  const meta = to.meta || {}; // meta信息中包含 当前页面是否需要登录才允许访问
  const { redirectUrl = '' } = to.query;
  console.log(to, redirectUrl);
  const menuName = to.name || meta.menuName || ''; // 路由名称
  const menuList = store.getters.menuList; // 菜单权限
  const { allPermissionCode = [], viewPermissionCode = [], noPermissionCode = [] } = menuList;
  if (getToken()) {
    if (to.path === '/login') {
      // 如果登录成功访问登录页跳转到主页
      next({ path: redirectUrl || '/' });
    } else {
      if (((noPermissionCode.length === viewPermissionCode.length) === noPermissionCode.length) === 0) {
        next('/login');
      } else {
        if (
          noPermissionCode.includes(menuName) &&
          !viewPermissionCode.includes(menuName) &&
          !allPermissionCode.includes(menuName)
        ) {
          // 用户访问当前路由，角色不存在该菜单权限，驳回
          next(false);
        } else {
          next();
        }
      }
    }
  } else {
    if (meta['isAuth'] === false) {
      next();
    } else {
      next('/login');
    }
  }
});

router.afterEach((to) => {
  document.title = to.name ? `${to.name} - 神码` : '';
});
