import Cookies from 'js-cookie';
import { getStore } from '@/utils/store';
const TokenKey = getStore('tokenKey') || 'accessToken';

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token, expiresTime) {
  const expirationTime = new Date();
  return Cookies.set(TokenKey, token, { expires: expirationTime.getTime() + expiresTime / 1000 }); // 设置token过期时间为2天
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}
