<template>
  <a-layout-sider v-model="collapsed" :trigger="null" collapsible>
    <div class="logo" />

    <a-menu theme="dark" mode="inline" :default-open-keys="openKeys" :default-selected-keys="[$route.path]">
      <template v-for="menu in menus">
        <a-menu-item v-if="!menu['children']" @click="toPage" :key="menu.path">
          <a-icon :type="menu.meta.icon" />
          <span>{{ menu.name }}</span>
        </a-menu-item>

        <!-- <a-sub-menu v-else :title="menu.name" :key="menu.path">
          <a-menu-item v-for="child in menu.children" :key="child.path" @click="toPage">
            <a-icon :type="child.meta.icon" />
            <span>{{ child.name }}</span>
          </a-menu-item>
        </a-sub-menu> -->
        <a-sub-menu v-else :title="menu.name" :key="menu.path">
          <a-menu-item v-for="child in menu.children" v-if="!child.children" :key="child.path" @click="toPage">
            <a-icon :type="child.meta.icon" />
            <span>{{ child.name }}</span>
          </a-menu-item>
          <a-sub-menu v-if="child.children" v-for="child in menu.children" :title="child.name" :key="child.path">
            <a-menu-item v-for="child1 in child.children" :key="child1.path" @click="toPage">
              <a-icon :type="child1.meta.icon" />
              <span>{{ child1.name }}</span>
            </a-menu-item>
          </a-sub-menu>
        </a-sub-menu>
      </template>
    </a-menu>

    <div class="weapp-code">
      <div class="title">抖音小程序体验码</div>
      <img class="app-code" src="../../assets/code.png" />
    </div>
  </a-layout-sider>
</template>

<script>
import viewRouters from '@/router/views';
import store from '@/store';

export default {
  props: {
    collapsed: Boolean
  },

  computed: {
    openKeys() {
      const { matched } = this.$route;
      const { path } = matched[0];
      return [path];
    },

    menus() {
      return this.getRoutes(viewRouters);
    }
  },

  methods: {
    toPage({ key }) {
      this.$router.push(key);
    },

    /**
     * @description 获取可见菜单
     * 1. 必须有name，才需要展示在左侧菜单中
     * 2. meta中可能会存在isMenu = false，表示不需要展示在左侧菜单中
     * 3. 路由中存在children，需要向下检索，直到没有children为止
     * @param routes
     * @returns {*[]}
     */
    getRoutes(routes) {
      const menuList = store.getters.menuList;
      let { allPermissionCode = [], viewPermissionCode = [], noPermissionCode = [] } = menuList;

      let arr = [];
      routes.forEach((r) => {
        const { isMenu = true } = r.meta || {};
        if (!isMenu) return;
        if (r.name) {
          if (noPermissionCode.includes(r.name)) return;
          if (!allPermissionCode.includes(r.name) && !viewPermissionCode.includes(r.name)) return;
        }

        if (r.name && !Array.isArray(r.children)) {
          arr.push(r);
        } else if (r.name && Array.isArray(r.children)) {
          const showChild = r.children.filter((o) => {
            const { isMenu = true, menuName } = o.meta || {};

            if (menuName) {
              if (noPermissionCode.includes(menuName)) return false;
              if (!allPermissionCode.includes(menuName) && !viewPermissionCode.includes(menuName)) return false;
            }

            return isMenu;
          });
          const childrenRoutes = showChild.map((o) => {
            if (o.path.startsWith('/')) {
              return o;
            } else {
              console.log('o.children', o.children);
              return {
                ...o,
                path: `${r.path}/${o.path}`,
                children: !o.children
                  ? null
                  : o.children.map((c) => {
                      if (c.path.startsWith('/')) {
                        return c;
                      } else {
                        return {
                          ...c,
                          path: `${r.path}/${o.path}/${c.path}`
                        };
                      }
                    })
              };
            }
          });
          if (r.name) {
            arr.push(Object.assign({}, r, { children: childrenRoutes }));
          } else {
            arr = arr.concat(childrenRoutes);
          }
        } else if (!r.name && Array.isArray(r.children)) {
          const showChild = r.children.filter((o) => {
            const { isMenu = true, menuName } = o.meta || {};

            if (menuName) {
              if (noPermissionCode.includes(menuName)) return false;
              if (!allPermissionCode.includes(menuName) && !viewPermissionCode.includes(menuName)) return false;
            }

            return isMenu;
          });
          const childrenRoutes = showChild.map((o) => {
            if (o.path.startsWith('/')) {
              return o;
            } else {
              return {
                ...o,
                path: `${r.path}/${o.path}`
              };
            }
          });

          if (r.name) {
            arr.push(childrenRoutes);
          } else {
            arr = arr.concat(childrenRoutes);
          }
        }
      });
      return arr;
    }
  }
};
</script>

<style>
.ant-layout-sider {
  height: 100vh;
  overflow-y: auto;
}
</style>

<style lang="less" scoped>
.weapp-code {
  //position: absolute;
  //left: 20px;
  //right: 20px;
  //bottom: 20px;
  margin: 20px auto 0;
  .title {
    color: #ffffff;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .app-code {
    display: block;
    width: 120px;
    height: auto;
    margin: 0 auto;
  }
}
</style>
