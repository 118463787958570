import store from '@/store';
import router from '@/router';
const disableClick = (ev) => ev && ev.stopImmediatePropagation();

export default {
  install(Vue) {
    Vue.directive('allow', {
      inserted: (el, binding) => {
        const { value } = binding;
        const menuList = store.getters.menuList;
        const { viewPermissionCode = [] } = menuList || {};
        const route = router.app.$route;
        const parentRoute = route.matched[0];
        const meta = route.meta;
        const menuName = parentRoute.name || meta.menuName || route.name || '';
        if (viewPermissionCode.includes(menuName)) {
          if (value === 'link') {
            el.setAttribute('disabled', 'disabled');
            el.addEventListener('click', disableClick, true);
          } else {
            el.remove();
          }
        }
      },

      unbind: (el) => {
        el.removeEventListener('click', disableClick);
      }
    });
  }
};
