import Layout from '@/pages/index';
import subLayout from '@/pages/subIndex.vue';
/**
 * views router.
 */
export default [
  {
    path: '/store',
    component: Layout,
    redirect: '/store/manage',
    children: [
      {
        path: 'manage',
        name: '店铺列表',
        component: () => import(/* webpackChunkName: "views" */ '@/views/store'),
        meta: {
          icon: 'shop',
          menuName: '店铺管理'
        }
      },
      {
        path: 'update',
        name: '店铺更新',
        component: () => import(/* webpackChunkName: "views" */ '@/views/store/update'),
        meta: {
          isMenu: false,
          icon: 'shop',
          menuName: '店铺管理'
        }
      }
    ]
  },
  {
    path: '/reserve',
    component: Layout,
    redirect: '/reserve/manage',
    children: [
      {
        path: 'manage',
        name: '预约管理',
        component: () => import(/* webpackChunkName: "views" */ '@/views/reserve'),
        meta: {
          icon: 'history',
          menuName: '预约管理'
        }
      }
    ]
  },
  {
    path: '/customer',
    component: Layout,
    redirect: '/customer/manage',
    name:'客户管理',
    children: [
      {
        path: 'manage',
        name: '客户管理',
        component: () => import(/* webpackChunkName: "views" */ '@/views/customer'),
        meta: {
          icon: 'user',
          menuName: '客户管理'
        }
      },
      {
        path: 'detail',
        name: '客户详情',
        component: () => import(/* webpackChunkName: "views" */ '@/views/customer/detail'),
        meta: {
          icon: 'user',
          menuName: '客户详情',
          isMenu: false,
        }
      },
      {
        path: 'tagList',
        name: '标签管理',
        component: () => import(/* webpackChunkName: "views" */ '@/views/tag/list.vue'),
        meta: {
          icon: 'user',
        }
      },
      {
        path: 'tagDetail',
        name: '标签详情',
        component: () => import(/* webpackChunkName: "views" */ '@/views/tag/detail.vue'),
        meta: {
          icon: 'user',
          isMenu: false,
        }
      }
    ]
  },
  {
    path: '/staff',
    name: '员工管理',
    component: Layout,
    redirect: '/staff/account',
    meta: { icon: 'user' },
    children: [
      {
        path: 'account',
        name: '账户管理',
        component: () => import(/* webpackChunkName: "views" */ '@/views/staff/account/manage.vue'),
        meta: {
          icon: 'team'
        }
      },
      {
        path: 'role',
        name: '角色管理',
        component: () => import(/* webpackChunkName: "views" */ '@/views/staff/role/role-manage.vue'),
        meta: {
          icon: 'user'
        }
      },
      {
        path: 'craftsman',
        name: '手艺人管理',
        component: () => import(/* webpackChunkName: "views" */ '@/views/staff/craftsman/craftsman-manage.vue'),
        meta: {
          icon: 'team'
        }
      }
    ]
  },
  {
    path: '/goods',
    component: Layout,
    redirect: '/goods/manage',
    name: '商品管理',
    children: [
      {
        path: 'manage',
        name: '商品列表',
        component: () => import(/* webpackChunkName: "views" */ '@/views/goods'),
        meta: {
          icon: 'shopping'
        }
      },
      {
        path: 'update',
        name: '商品更新',
        component: () => import(/* webpackChunkName: "views" */ '@/views/goods/update.vue'),
        meta: {
          isMenu: false
        }
      },
      {
        path: 'group',
        name: '商品分组',
        component: () => import(/* webpackChunkName: "views" */ '@/views/goods/goods-group.vue'),
        meta: {
          icon: 'profile'
        }
      }
    ]
  },
  {
    path: '/order',
    component: Layout,
    redirect: '/order/manage',
    name: '订单管理',
    children: [
      {
        path: 'manage',
        name: '订单列表',
        component: () => import(/* webpackChunkName: "views" */ '@/views/order'),
        meta: {
          icon: 'container'
        }
      },
      {
        path: 'detail',
        name: '订单详情',
        component: () => import(/* webpackChunkName: "views" */ '@/views/order/order-detail.vue'),
        meta: {
          isMenu: false,
          icon: 'user'
        }
      },
      {
        path: 'after-sale',
        name: '售后维权',
        component: () => import(/* webpackChunkName: "views" */ '@/views/order/after-sale.vue'),
        meta: {
          icon: 'user'
        }
      },
      {
        path: 'after-sale-detail',
        name: '售后详情',
        component: () => import(/* webpackChunkName: "views" */ '@/views/order/after-sale-detail.vue'),
        meta: {
          isMenu: false,
          icon: 'user'
        }
      }
    ]
  },
  {
    path: '/to-align',
    component: Layout,
    redirect: '/to-align/list',
    name: '加盟管理',
    children: [
      {
        path: 'list',
        name: '加盟列表',
        component: () => import(/* webpackChunkName: "views" */ '@/views/to-align/list.vue'),
        meta: {
          icon: 'contacts'
        }
      },
      {
        path: 'settings',
        name: '加盟设置',
        component: () => import(/* webpackChunkName: "views" */ '@/views/to-align/settings.vue'),
        meta: {
          icon: 'setting'
        },
      },
    ],
  },
  {
    path: '/scrm',
    component: Layout,
    redirect: '/scrm/share',
    name: 'SCRM',
    children:[
      {
        path: 'share',
        name: '推广设置',
        component: () => import(/* webpackChunkName: "views" */  '@/views/scrm/share/index.vue'),
        meta: {
          icon: 'user',
          // menuName: '推广设置'
        },
      },
      {
        path: 'analyze',
        name: '统计分析',
        redirect: '/scrm/analyze/list',
        component: subLayout,
        meta: {
          icon: 'user',
          // menuName: '推广设置'
        },
        children:[
          {
            path: 'funnelAnalyze',
            name: '漏斗分析',
            component: () => import(/* webpackChunkName: "views" */ '@/views/scrm/analyze/funnelAnalyze.vue'),
            meta: {
              icon: 'setting'
            },
          },
          {
            path: 'dataAnalyze',
            name: '数据分析',
            component: () => import(/* webpackChunkName: "views" */ '@/views/scrm/analyze/dataAnalyze.vue'),
            meta: {
              icon: 'setting'
            },
          },
        ]
      },
    ]
  },
  {
    path:'/recharge',
    component:Layout,
    redirect:'/recharge/rules',
    name:'储值管理',
    children:[
      {
        path: 'rules',
        name: '储值规则',
        component: () => import(/* webpackChunkName: "views" */  '@/views/recharge/rules.vue'),
        meta: {
          icon: 'user',
        },
      },
      {
        path: 'record',
        name: '储值记录',
        component: () => import(/* webpackChunkName: "views" */  '@/views/recharge/record.vue'),
        meta: {
          icon: 'user',
        },
      },
    ]
  },

];
